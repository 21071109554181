import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { Box, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useState } from 'react'

import { useUserMenuDropdownStyles } from './hooks'

import { Avatar } from '../../../Avatar'
import { Icon } from '../../../Icon'

import {
  getEmail,
  getFullUsername,
  getIsUserProvider,
  getProfileImageUrl
} from '../../../../../util/new/currentUser'
import NamedRedirect from '../../../../NamedRedirect/NamedRedirect'

export const UserMenuDropdown = ({ onLogout, currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [redirect, setRedirect] = useState({ redirect: false, name: '' })
  const { classes } = useUserMenuDropdownStyles()

  const username = getFullUsername(currentUser)
  const email = getEmail(currentUser)
  const profileImageUrl = getProfileImageUrl(currentUser)
  const isProvider = getIsUserProvider(currentUser)

  const isOpen = Boolean(anchorEl)

  const angleIconClasses = classNames(classes.angleIcon, isOpen && classes.rotatedAngleIcon)

  const profileClickHandler = () => {
    setRedirect({ redirect: true, name: 'ProfileSettingsPage' })
  }

  const clickHandler = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeHandler = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      <button className={classes.container} onClick={clickHandler}>
        <Avatar imageUrl={profileImageUrl} />
        <Icon icon={faAngleDown} className={angleIconClasses} />
      </button>
      <Menu
        id="user-profile-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeHandler}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{ paper: classes.menu }}
      >
        <Box className={classes.userInfo} onClick={profileClickHandler}>
          <Typography className={classes.userName}>{username}</Typography>
          <Typography className={classes.userEmail}>{email}</Typography>
        </Box>
        <Divider className={classes.divider} />
        <MenuItem onClick={() => setRedirect({ redirect: true, name: 'ContactDetailsPage' })}>
          Settings
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem
          component="a"
          href={
            isProvider
              ? 'https://www.seestella.com.au/pages/for-practitioners'
              : 'https://www.seestella.com.au/'
          }
          target="_blank"
        >
          Learning center
        </MenuItem>
        <MenuItem component="a" href="https://www.seestella.com.au/pages/contact" target="_blank">
          Contact us
        </MenuItem>
        <MenuItem onClick={onLogout}>Sign out</MenuItem>
      </Menu>
      {redirect.redirect && <NamedRedirect name={redirect.name} />}
    </Box>
  )
}

import { TextField } from '@material-ui/core'
import { useEffect, useState } from 'react'

export const InputField = ({
  variant = 'outlined',
  size = 'medium',
  type = 'text',
  isMultiline = false,
  isDisabled = false,
  isRequired = false,
  isError: externalIsError = false,
  minRows,
  label,
  placeholder,
  generalHelperText,
  errorHelperText,
  value,
  setValue,
  onChange,
  onFocus,
  onBlur,
  InputProps,
  inputProps,
  fullWidth,
  validate, // New prop for validation function
  style
}) => {
  const [helperText, setHelperText] = useState(generalHelperText)
  const [isError, setIsError] = useState(externalIsError)

  useEffect(() => {
    if (validate) {
      const validationResult = validate(value)
      if (validationResult) {
        setHelperText(validationResult)
        setIsError(true)
      } else {
        setHelperText(generalHelperText)
        setIsError(false)
      }
    } else if (externalIsError) {
      setHelperText(errorHelperText)
      setIsError(true)
    } else {
      setHelperText(generalHelperText)
      setIsError(false)
    }
  }, [value, validate, generalHelperText, errorHelperText, externalIsError])

  const changeHandler = (event) => {
    const { value } = event.target
    onChange(value)
    if (setValue) {
      setValue(value)
    }
  }

  const focusHandler = () => {
    if (onFocus) {
      onFocus()
    }
  }

  const blurHandler = () => {
    if (onBlur) {
      onBlur()
    }
  }

  return (
    <TextField
      variant={variant}
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      value={value}
      type={type}
      size={size}
      multiline={isMultiline}
      minRows={minRows}
      disabled={isDisabled}
      required={isRequired}
      error={isError}
      onChange={changeHandler}
      onFocus={focusHandler}
      onBlur={blurHandler}
      InputProps={InputProps}
      inputProps={inputProps}
      fullWidth={fullWidth}
      style={style}
    />
  )
}

export * from './components'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { AnalyticsEvent, trackEvent } from '../../analytics/handlers'
import css from './NotificationBanner.module.css'

const NotificationBanner = (props) => {
  const { currentUser, rootClassName, className } = props
  const classes = classNames(rootClassName || css.root, className)

  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    setShowBanner(window.sessionStorage.getItem('showNotificationBanner') !== 'false')
  }, [])

  return showBanner ? (
    <div className={classes}>
      {currentUser?.attributes?.profile?.privateData?.userType === 'provider' ? (
        <p className={css.text}>
          Check Out Our Playbooks for Practical Info & Checklists to Help You Grow, Compliantly.{' '}
          <a
            onClick={() => {
              trackEvent(AnalyticsEvent.bannerPlaybookLibraryTapped)
              window.open('https://www.seestella.com.au/pages/playbooks', '_blank')
            }}
          >
            Go To Playbook Library
          </a>
        </p>
      ) : (
        <p className={css.text}>
          Stella is Australia's First Online Directory for Expert Cosmetic Practitioners.{' '}
          <a
            onClick={() => {
              trackEvent(AnalyticsEvent.bannerLearnMoreTapped)
              window.open('https://seestella.com.au', '_blank')
            }}
          >
            Learn More About Us Here
          </a>
        </p>
      )}
      <IconButton
        size="small"
        onClick={() => {
          window.sessionStorage.setItem('showNotificationBanner', 'false')
          setShowBanner(false)
        }}
      >
        <Close />
      </IconButton>
    </div>
  ) : (
    <></>
  )
}

NotificationBanner.defaultProps = {
  rootClassName: null,
  className: null
}

const { string } = PropTypes

NotificationBanner.propTypes = {
  rootClassName: string,
  className: string
}

export default NotificationBanner

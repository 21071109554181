import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  const { palette } = theme

  return {
    container: {
      width: '250px',
      height: '42px'
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    title: {
      color: palette.primary.main,
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '26px',
      padding: 0,
      letterSpacing: 0
    },
    locationArrowIcon: {
      fontSize: '18px',
      color: palette.primary.main
    }
  }
})

export const useUseMyLocationButtonStyles = () => {
  const classes = useStyles()
  return { classes }
}

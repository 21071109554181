export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url)
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window) {
      window.setTimeout(() => {
        // Send to GTM
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'page_view',
          page_path: canonicalPath
        })

        // if (window.gtag) {
        //   // Send directly to GA4
        //   window.gtag('event', 'page_view', {
        //     page_path: canonicalPath
        //   })
        // }
      }, 300)
    }
  }
}

export const AnalyticsEvent = {
  bookingRequestVoucherOpened: 'booking_request_voucher_opened',
  bookingRequestTapped: 'booking_request_tapped',
  bookingRequestVoucherClaimed: 'booking_request_voucher_claimed',
  instagramTapped: 'instagram_tapped',
  visitWebsiteTapped: 'visit_website_tapped',
  visitClinicTapped: 'visit_clinic_tapped',
  shareButtonTapped: 'share_button_tapped',
  requestToModelTapped: 'request_to_model_tapped',
  writeReviewTapped: 'write_review_tapped',
  writeReviewSubmitted: 'write_review_submitted',
  consultationSearchTapped: 'consultation_search_tapped',
  postConcernSearchTapped: 'post_concern_search_tapped',
  contactRequestForConsultTapped: 'contact_request_for_consult_tapped', // ARCHIVED
  replyConsultRequestTapped: 'reply_consult_request_tapped',
  replyConsultRequestSubmitted: 'reply_consult_request_submitted',
  consultationProfileTapped: 'consultation_profile_tapped',
  consultationLearnMoreTapped: 'consultation_learn_more_tapped',
  consultationBookTapped: 'consultation_book_tapped',
  bannerLearnMoreTapped: 'banner_learn_more_tapped',
  bannerPlaybookLibraryTapped: 'banner_playbook_library_tapped',
  practitionerConsultationTapped: 'practitioner_consultation_tapped',
  onlineConciergeTapped: 'online_concierge_tapped',
  voucherViewed: 'voucher_viewed',
  errorShown: 'error_shown'
}

export const trackEvent = (eventName, eventParams) => {
  if (process.env.REACT_APP_ENV === 'development') {
    console.log('Track Event:', eventName, eventParams)
  }

  if (window && window.location.href.includes('app.seestella.com.au')) {
    // if (window.gtag) {
    //   window.gtag('event', eventName, eventParams)
    // }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: eventName,
      ...eventParams
    })
  }
}
